import React from 'react';
import './Navbar.css';

const Navbar = ({ activeTab, setActiveTab }) => {
  return (
    <nav className="navbar">
      <h1 className="navbar-title">JSON Simplifier</h1>
      <div className="navbar-tabs">
        <button 
          className={`navbar-tab ${activeTab === 'formatter' ? 'active' : ''}`} 
          onClick={() => setActiveTab('formatter')}
        >
          JSON Formatter
        </button>
        <button 
          className={`navbar-tab ${activeTab === 'validator' ? 'active' : ''}`} 
          onClick={() => setActiveTab('validator')}
        >
          JSON Validator
        </button>
        <button 
          className={`navbar-tab ${activeTab === 'load' ? 'active' : ''}`} 
          onClick={() => setActiveTab('load')}
        >
          Load JSON from URL
        </button>
        <button 
          className={`navbar-tab ${activeTab === 'filter' ? 'active' : ''}`} 
          onClick={() => setActiveTab('filter')}
        >
          JSON Filter
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
