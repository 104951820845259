import React, { useState, useRef } from 'react';
import ReactJson from 'react-json-view';
import './JsonViewer.css';
import Navbar from '../navbar/Navbar'; // Import the Navbar component

function JsonViewer() {
  const [jsonInput, setJsonInput] = useState('');
  const [parsedJson, setParsedJson] = useState(null);
  const [error, setError] = useState('');
  const [width, setWidth] = useState(35); // percentage of the input width
  const [searchKey, setSearchKey] = useState(''); // State for search key
  const [activeTab, setActiveTab] = useState('formatter'); // State for active tab
  const [currentMatchIndex, setCurrentMatchIndex] = useState(0); // For tracking the current match
  const matches = useRef([]); // To store the matched positions

  const handleInputChange = (e) => {
    setJsonInput(e.target.value);
  };

  const handleParseJson = () => {
    try {
      const parsed = JSON.parse(jsonInput);
      setParsedJson(parsed);
      setError('');
      matches.current = []; // Reset matches when JSON is parsed
    } catch (err) {
      setError('Invalid JSON format');
      setParsedJson(null);
    }
  };

  const handleClearJson = () => {
    setJsonInput('');
    setParsedJson(null);
    setError('');
    matches.current = [];
  };

  const handleMouseDown = (e) => {
    e.preventDefault();

    const handleMouseMove = (event) => {
      const newWidth = ((event.clientX / window.innerWidth) * 100);
      if (newWidth > 10 && newWidth < 90) {
        setWidth(newWidth);
      }
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleEdit = (edit) => {
    // Edit handling logic
  };

  const handleAdd = (add) => {
  };

  const handleDelete = (del) => {
    const updatedJson = { ...parsedJson };
    delete updatedJson[del.name];
    setParsedJson(updatedJson);
  };

  const handleSearchChange = (e) => {
    setSearchKey(e.target.value);
    setCurrentMatchIndex(0); // Reset current match when the search key changes
  };

  const handleNext = () => {
    if (matches.current.length > 0) {
      setCurrentMatchIndex((currentMatchIndex + 1) % matches.current.length);
    }
  };

  const handlePrevious = () => {
    if (matches.current.length > 0) {
      setCurrentMatchIndex(
        (currentMatchIndex - 1 + matches.current.length) % matches.current.length
      );
    }
  };

  // Search and highlight function to track matches
  const highlightSearch = (json, searchKey) => {
    if (!searchKey) return json;
    const highlightedJson = {};
    matches.current = [];
    for (const key in json) {
      let value = json[key];
      let highlightedKey = key;
      if (key.includes(searchKey)) {
        highlightedKey = key.replace(new RegExp(searchKey, 'gi'), (match) => `<mark>${match}</mark>`);
        matches.current.push(key);
      }
      highlightedJson[highlightedKey] = value;
    }
    return highlightedJson;
  };

  return (
    <div>
      <Navbar activeTab={activeTab} setActiveTab={setActiveTab} />
      <div className="container">
        <div className="json-viewer" style={{ maxHeight: '90%', overflow: 'scroll' }}>
          
          {/* JSON Input Container */}
          <div className="json-input-container" style={{ maxHeight: '85%', width: `${width}%` }}>
            <textarea
              rows="10"
              placeholder="Paste your JSON here..."
              value={jsonInput}
              onChange={handleInputChange}
              className="json-input"
            />
            <div className="button-group">
              <button onClick={handleParseJson} className="parse-button">
                Parse JSON
              </button>
              <button onClick={handleClearJson} className="clear-button">
                Clear JSON
              </button>
            </div>
            {error && <p className="error">{error}</p>}
          </div>

          <div className="divider" onMouseDown={handleMouseDown} />

          {/* JSON Output and Search */}
          <div className="json-output" style={{ width: `${100 - width}%`, position: 'relative' }}>
            
            {/* Fixed Search Bar */}
            {/* <div className="json-search-overlay" style={{ position: 'sticky', display:'flex',justifyContent: 'flex-end'}}>
              <div style={{width: 'max-content' ,  backgroundColor: "rgba(255, 255, 255, 0.4)", boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)" , padding: '10px'}}>
              <input
                type="text"
                placeholder="Search..."
                value={searchKey}
                onChange={handleSearchChange}
                style={{ marginRight: '10px' }}
              />
              <button onClick={handleNext} style={{ marginRight: '5px' }}>Next</button>
              <button onClick={handlePrevious}>Previous</button>
              </div>
            </div> */}

            {/* JSON Display */}
            {parsedJson && (
              <ReactJson
                src={highlightSearch(parsedJson, searchKey)}
                onEdit={handleEdit}
                onAdd={handleAdd}
                onDelete={handleDelete}
                enableClipboard={false}
                displayDataTypes={false}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default JsonViewer;
